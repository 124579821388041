<template>
  <div>
    <div class="_BoxHeader">
      <span>近期问题点统计</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <div class="time">
          <span class="demonstration">时间：</span>
          <el-date-picker
            style="width: 130px"
            v-model="time1"
            type="date"
            placeholder="开始时间"
            @change="changTime"
          >
          </el-date-picker>
          <span style="margin: 0 5px">至</span>
          <el-date-picker
            style="width: 130px"
            v-model="time2"
            type="date"
            placeholder="结束时间"
            @change="changTime"
          >
          </el-date-picker>
        </div>
        <div class="_Select" style="width: 300px">
          <span>公司：</span>
          <el-select
            v-model="compny"
            clearable
            placeholder="请选择"
            @change="changCompny"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table">
        <el-table
          :height="elementHeight"
          :data="tableList"
          style="width: 100%"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          border
          id="table"
        >
          <el-table-column prop="question" label="问题"> </el-table-column>
          <el-table-column prop="answer" label="答案"> </el-table-column>
        </el-table>
      </div>
      <div class="_Pagination" v-if="pageShow">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      time1: '',
      time2: '',
      examRange: '区域检查',
      tableList: [],
      companyList: [],
      compny: '',
      // 分页
      currentPage: 1,
      pageSize: 10,
      count: 0,
      pageShow: true,
    }
  },
  created() {
    this.compny = JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId;
  },
  mounted() {
    this.companyList = this.$store.state.companyUserList
    this.getElementHeight()
    var nextTime = new Date()
    nextTime.setMonth(nextTime.getMonth() - 1)
    this.getTableList(nextTime.toISOString(),new Date().toISOString())
    this.time1 = nextTime.toISOString()
    this.time2 = new Date().toISOString()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 165)
      })
    },
    changTime() {
      this.pageShow = false
      this.currentPage = 1
      if (this.time1 && this.time2)
        this.getTableList(
          new Date(this.time1).toISOString(),
          new Date(this.time2).toISOString()
        )
    },
    changCompny() {
      this.getTableList(
        new Date(this.time1).toISOString(),
        new Date(this.time2).toISOString()
      )
    },
    getTableList(formData, toData) {
      this.pageShow = false
      // this.currentPage = 1
      this.tableList = []
      get(
        '/api/RecentStatistics/GetRecentAuditPage' +
          '?FromDate=' +
          formData +
          '&ToDate=' +
          toData +
          '&limit=' +
          this.pageSize +
          '&page=' +
          this.currentPage +
          (this.compny ? '&CompId=' + this.compny : '')
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.tableList = resp.data
            this.count = resp.count
          }
          this.pageShow = true
        })
        .catch(() => {
          this.pageShow = true
        })
    },
    handleSizeChange(val) {
      // this.currentPage = 1
      this.pageSize = val
      this.getTableList(
        new Date(this.time1).toISOString(),
        new Date(this.time2).toISOString()
      )
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableList(
        new Date(this.time1).toISOString(),
        new Date(this.time2).toISOString()
      )
    },
  },
}
</script>

<style lang="less" scoped>
.table {
  border-top: 1px solid rgb(224, 224, 224);
  padding: 5px 20px;
}
.time {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}
/deep/ .el-range-separator {
  line-height: 28px !important;
}
</style>